<template>
  <!-- style="
      height: calc(
        100vh - var(--header-height) - var(--nav-height) - var(--main-padding) * 2 - (36px + 20px) -
          (22px + 20px)- (22px + 20px)
      );
    " -->
  <el-scrollbar
    class="content-right__lists inspection-defects__table"
    :style="{ height: tableHeight + 'px' }"
  >
    <template v-for="(listsItem, index) in lists">
      <div
        v-if="formData.type === '' || formData.type === listsItem.listQuery.type"
        class="list-item"
        :key="listsItem.label"
      >
        <div class="list-item__label _font-size__sub__heading _font-weight__600">
          {{ listsItem.label }}
        </div>
        <!-- 
          width: calc(var(--main-width) - 490px - 40px - 50px)
          这里需要使用计算，不能使用 100% ，因为 100% 会有个计算过程需要等待父级计算结束，在进行计算
          100vw 则在页面渲染立即计算不需要等待父级计算。
          490px 为左侧元素宽度 50px 为左右元素之间间距，40px 右侧元素左右内边距和
          -->
        <PowerFulTable
          :data="listsItem.listResult.rows"
          :columns="columns"
          :options="{ ...options, loading: listsItem.loading }"
          :loading="listsItem.loading"
          :surplusHeight="48"
          @sortCustom="handleSortCustom($event, listsItem)"
          @handleCurrentChange="handleCurrent($event, index)"
        />

        <el-pagination
          v-if="Number(listsItem.listResult.total) !== 0"
          background
          layout="->, prev, pager, next"
          :total="Number(listsItem.listResult.total)"
          @current-change="handleCurrentChange($event, listsItem)"
        ></el-pagination>
      </div>
    </template>
  </el-scrollbar>
</template>

<script>
import PowerFulTable from '@/components/table/powerful-table'
import { alertFindPage, getDefectTypeList } from '@/api/home'
import { optionsFun } from '@/utils'
import { listFormView, headers } from '../index-data'

export default {
  components: {
    PowerFulTable
  },
  props: {
    // 肯定不为 undefined 和 {}
    formData: Object
  },
  data() {
    return {
      options: {
        property: { highlightCurrentRow: true, height: 210 }
      },
      lists: [],
      columns: headers,

      tableHeight: 500
    }
  },
  activated() {
    getDefectTypeList().then((res) => {
      listFormView.find((item) => item.prop === 'type').options = optionsFun(res.result)
      this.lists = res.result.map((item) => {
        return {
          label: item,
          listResult: {
            rows: [],
            total: 0
          },
          loading: true,
          listQuery: {
            type: item,
            orderBy: '',
            sort: '',
            pageNo: 1,
            pageSize: 10
          }
        }
      })

      this.lists.forEach((item) => {
        this.getData(item)
      })
    })

    this.getHeight()
  },
  methods: {
    handleCurrent(row) {
      this.$emit('current', row)
    },
    getData(listsItem) {
      listsItem.loading = true
      alertFindPage({ ...this.formData, ...((listsItem && listsItem.listQuery) || {}) })
        .then((res) => {
          listsItem.loading = false
          if (listsItem) {
            listsItem.listResult = res.result
          }
        })
        .catch(() => {
          listsItem.loading = false
        })
    },
    handleSortCustom({ prop, order }, listsItem) {
      listsItem.listQuery.orderBy = prop
      listsItem.listQuery.sort = order ? order.replace('ending', '') : ''
      this.getData(listsItem)
    },
    handleCurrentChange(e, listsItem) {
      listsItem.listQuery.pageNo = e
      this.getData(listsItem)
    },
    getHeight() {
      if (
        document.getElementsByClassName(this.options.className || 'inspection-defects__table')
          .length
      ) {
        // 30为底部空白 (+ || -)实际情况所偏差
        this.tableHeight =
          window.innerHeight -
          document
            .getElementsByClassName(this.options.className || 'inspection-defects__table')[0]
            .getBoundingClientRect().top -
          30
      }
    }
  }
}
</script>
